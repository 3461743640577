<template>
  <IonApp id="fuse-application" :class="currentFestiveThemeClassComputed">
    <IonSplitPane
      :when="$route.query.forceMenuClosed !== undefined ? '(min-width: 4000px)' : 'md'"
      id="nav-split-pane"
      content-id="main-content"
    >
      <ion-menu content-id="main-content" type="reveal">
        <ion-content>
          <menu-tabs></menu-tabs>
          <ion-item v-if="currentFestiveThemeComputed" class="festive-theme-toggle-item">
            <ion-toggle
              style="padding-left: 10px"
              slot="start"
              :checked="festiveThemeOn"
              @ion-change="toggleFestiveTheme()"
            ></ion-toggle>
            <ion-label>Ünnepi téma</ion-label>
          </ion-item>
          <ion-toolbar style="margin-bottom: 50px" class="bottom-switches-toolbar">
            <ion-button
              v-tooltip
              title="Sötét mód választó"
              fill="clear"
              @click="changeDarkMode"
            >
              <ion-icon :md="darkModeIcon" :ios="darkModeIcon"></ion-icon>
            </ion-button>
            <ion-button v-tooltip title="Nyelv" fill="clear">
              <ion-select
                :interface-options="customPopoverOptions"
                interface="popover"
                :value="$i18n.locale"
                @ionChange="newLocalSelected($event)"
              >
                <ion-select-option
                  v-for="locale in $i18n.availableLocales"
                  :key="locale"
                  :value="locale"
                >
                  {{ locale.toUpperCase() }}
                </ion-select-option>
              </ion-select>
            </ion-button>
            <ion-label
              v-longpress="() => toggleEasterEggTheme()"
              slot="end"
              class="version"
            >
              <p>v{{ version }}<br />{{ isAndroid ? "" : "#web" }}</p>
            </ion-label>
          </ion-toolbar>
          <ion-item
            lines="none"
            class="sync-item"
            v-if="$store.getters['app/getBackgroundSyncStatus'] === 'pending'"
          >
            <ion-label>
              <ion-icon
                style="color: var(--ion-color-primary)"
                class="sync-spinner"
                :icon="syncIcon"
              ></ion-icon>
              {{ $t("Downloading offline data") }}...
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            class="sync-item"
            v-else-if="$store.getters['app/getBackgroundSyncStatus'] === 'error'"
          >
            <ion-label>
              <ion-icon
                style="color: var(--ion-color-warning)"
                :icon="warningIcon"
              ></ion-icon>
              {{ $t("Offline mode") }}
            </ion-label>
          </ion-item>
        </ion-content>
      </ion-menu>
      <ion-page id="main-content">
        <ion-header :translucent="true">
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button color="primary"></ion-menu-button>
              <ion-button
                size="large"
                shape="round"
                color="primary"
                v-if="$route.meta.hasBackButton"
                @click="$router.back()"
              >
                <ion-icon :ios="back" :md="back"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-title>{{ $t(title) }}</ion-title>
            <div slot="end" id="toprightmenu"></div>
          </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="false">
          <ion-header collapse="condense">
            <ion-toolbar>
              <ion-title size="large">{{ title }}</ion-title>
            </ion-toolbar>
          </ion-header>

          <div id="container">
            <router-view></router-view>
          </div>
        </ion-content>
      </ion-page>
    </IonSplitPane>

    <ion-toast
      :isOpen="isToastOpen"
      :header="notification.title"
      :message="notification.body"
      :buttons="notificationToastButtons"
      layout="stacked"
    ></ion-toast>
  </IonApp>
  <div id="teleportforbackdrop"></div>
</template>

<script lang="js">
import {
  IonToast,
  useBackButton,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonApp,
  IonContent,
  IonIcon,
  IonLabel,
  IonMenu,
  IonItem,
  IonSplitPane,
  alertController,
  isPlatform,
  IonToggle
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {contrast, moon, sunny, arrowBack, syncOutline, warningOutline} from 'ionicons/icons';
import { modalController } from "@ionic/vue";
import MenuTabs from './components/MenuTabs.vue';
import {mixin} from './mixins/mixin';
import {Plugins} from '@capacitor/core';
import {appConfig} from './config';
import {getToken, onMessage} from "firebase/messaging";
import {configWithAuth} from '@/store/api_configs'

import {
  PushNotifications,
} from '@capacitor/push-notifications';

const {App} = Plugins;


export default defineComponent({
  name: 'App',
  mixins: [mixin],
  components: {
    IonToggle,
    IonToast,
    IonSelect, IonSelectOption,
    IonApp,
    IonContent,
    IonIcon,
    IonLabel,
    IonMenu,
    IonSplitPane,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    MenuTabs,
    IonItem,
  },
  methods: {
    toggleFestiveTheme(){
      if(this.festiveThemeOn){
        localStorage.setItem('festive-theme-disabled', 'true')
        this.festiveThemeOn = false
      } else {
        localStorage.removeItem('festive-theme-disabled')
        this.festiveThemeOn = true
      }
    },
    showClientUpdateAvailableMessage(){
      this.notification.title = "Frissítés elérhető!"
      this.notification.body = "Az alkalmazás zavartalan működéséhez kérlek frissítsd az oldalt. Frissítés előtt ajánlott szinkronizálni az esetleges adatvesztés elkerülése miatt."
      this.notificationToastButtons = [
        {
          text: this.$i18n?.t('Reload'),
          side: 'end',
          handler: ()=>{
            this.isToastOpen = true
            location.reload()

          }
        }
      ]
      this.isToastOpen = true
    },
    checkAPIAvailability() {
      return this.$http(configWithAuth('POST','ping'))
        .then((r) => {
          if (r.data) {
            const hash = localStorage.getItem("service-app-version-hash")
            if(r.data.data?.clientHash !== null &&  r.data.data?.clientHash !== hash){
              this.showClientUpdateAvailableMessage()

            }
            if(this.isFirstHash && r.data.data?.clientHash !== null){
              this.isFirstHash = false
              localStorage.setItem("service-app-version-hash", r.data.data?.clientHash)
            }
          }
        }).catch((e) => {
          console.log(e)
        })
    },
    toggleEasterEggTheme(){
      document.body.classList.remove('dark')
      document.body.classList.add('pink')
    },
    newLocalSelected(event) {
      if (event.detail.value !== this.$i18n.locale) {
        localStorage.setItem('active_locale', event.detail.value)
        this.$router.go(0)
      }
    },
    changeDarkMode() {
      if (!this.darkMode || this.darkMode === 'auto')
        this.$store.commit('app/setDarkMode', 'true')
      else if (this.darkMode === 'true')
        this.$store.commit('app/setDarkMode', 'false')
      else if (this.darkMode === 'false')
        this.$store.commit('app/setDarkMode', 'auto')
      this.applyDarkMode()
    },
    applyDarkMode() {
      if (this.darkMode === 'auto')
        document.body.classList.toggle('dark', matchMedia('(prefers-color-scheme: dark)').matches)
      else
        document.body.classList.toggle('dark', this.darkMode === 'true');
    },
    async presentLoginExpiredAlert() {
       this.$store.dispatch('load/stopLoad')
      const alert = await alertController.create({
        header: this.$i18n?.t('Login expired'),
        message: this.$i18n?.t('Please login again'),
        buttons: [
          {
            text: this.$i18n?.t('Cancel'),
            role: 'cancel',
          },
          {
            text: this.$i18n?.t('Ok'),
            role: 'confirm',
            handler: () => {
              this.$store.dispatch('auth/logout')
            },
          },
        ],
      });

      await alert.present();
    },
    async presentUserChangeAlert() {
       this.$store.dispatch('load/stopLoad')
      const alert = await alertController.create({
        header: this.$i18n?.t('Another user has logged in'),
        message: this.$i18n?.t('You have opened this application from FUSE but another user has already logged in. Import user form FUSE or keep current account?'),
        buttons: [
          {
            text: this.$i18n?.t('Keep current user'),
            role: 'cancel',
            handler: async () => {
              await this.doInitialSync()
            },
          },
          {
            text: this.$i18n?.t('Import account'),
            role: 'confirm',
            handler: async () => {
              await this.$store.dispatch('auth/changeUserByAccessToken',{token:this.$route.query.access_token, uuid:this.$route.query.xuserid, reload: true})
            },
          },
        ],
      });

      await alert.present();
    },
    async presentAppUpdateAvailableAlert(downloadUrl, version, downloadSize) {
      const alert = await alertController.create({
        header: this.$i18n?.t('App update available'),
        message: this.$i18n?.t(`Please use latest version for improved functionality and stability. (${appConfig.version.replace('@dev', '')} -> ${version})`),
        buttons: [
          {
            text: this.$i18n?.t('Cancel'),
            role: 'cancel',
          },
          {
            text: this.$i18n?.t('Download') + (downloadSize ? ` (${downloadSize} mb)` : ''),
            role: 'confirm',
            handler: () => {
              window.location.href = downloadUrl
            },
          },
        ],
      });

      await alert.present();
    },
    async appVersionCheck(versionData){
      if(!(await isPlatform('cordova'))){ return;}
      localStorage.setItem('appVersions', JSON.stringify(versionData))
      const latestAppVersion = versionData.find(app=> app.appName === 'RBMS Fuse Service')
      const relaseVerion = latestAppVersion.releases.prod?.version.replace(/(^v)/gi, "")
      const debugVersion = latestAppVersion.releases.debug?.version.replace(/(^v)/gi, "")
      if(appConfig.version.replace('@dev', '') !== debugVersion && appConfig.version.replace('@dev', '') !== relaseVerion){
        this.presentAppUpdateAvailableAlert(latestAppVersion.releases.prod?.path || latestAppVersion.releases.debug?.path,
                                            latestAppVersion.releases.prod?.version || latestAppVersion.releases.debug?.version,
                                            latestAppVersion.releases.prod?.downloadSize || latestAppVersion.releases.debug?.downloadSize)
      }
    },
    async doInitialSync(){
      if(!this.$store.getters['user/getUser'] || !this.$store.getters['user/getUser'].email) {
        this.$store.commit('app/setBackgroundSyncStatus', 'complete')
      }
      this.$store.commit('app/setBackgroundSyncStatus', 'pending')
      this.$store.dispatch("auth/checkTokenValidity").then(async r => {
        this.appVersionCheck(r.data.data.apps)
        if(!this.$store.getters['user/getUser'] || !this.$store.getters['user/getUser'].email) { return; }
        if (r.response && r.response.status && r.response.status > 400 && r.response.status < 500
            && this.$store.getters['user/getUser'] &&this.$store.getters['user/getUser'].email) {// && r.message !== 'Network Error' && this.$route.fullPath !== "/auth/login")
          if(this.$route.query.access_token && this.$route.query.xuserid === this.$store.getters['user/getUser'].uuid){
            await this.$store.dispatch('auth/changeUserByAccessToken',{token:this.$route.query.access_token, uuid:this.$route.query.xuserid})
          } else{
            this.presentLoginExpiredAlert()
          }
        } else {
          this.$store.dispatch("offlineParts/downloadAndSaveParts")
                .then(() => this.$store.dispatch("app/downloadAndStoresupplementaryData")
                      .then(()=> this.$store.commit('app/setBackgroundSyncStatus', 'complete')))
        }
      })
      .catch(async (error) => {
        console.log(error)
        if (error.response && error.response.status && error.response.status > 400 && error.response.status < 500
            && this.$store.getters['user/getUser'] &&this.$store.getters['user/getUser'].email) {// && r.message !== 'Network Error' && this.$route.fullPath !== "/auth/login")
            if(this.$route.query.access_token && this.$route.query.xuserid === this.$store.getters['user/getUser'].uuid){
              await this.$store.dispatch('auth/changeUserByAccessToken',{token:this.$route.query.access_token, uuid:this.$route.query.xuserid})
              return
            } else{
              this.presentLoginExpiredAlert()
            }
        }
        this.$store.dispatch("app/downloadAndStoresupplementaryData")
        this.$store.commit('app/setBackgroundSyncStatus', 'error')
      })
    },
  async registerNotifications() {
      if (await isPlatform("cordova")) {
        await PushNotifications.addListener('pushNotificationReceived', notification => {
        this.notification = notification
        this.isToastOpen = true;
        (new Promise(r => setTimeout(r, 5000))).then(()=>this.isToastOpen = false);
      });
      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        if(notification.notification.data.link){
          this.$router.push(notification.notification.data.link+ (notification.notification.data.itemId ? `?highlightItem=${notification.notification.data.itemId}`:''))
        }
      })
        await PushNotifications.addListener("registration", (token) => {
          localStorage.setItem("fcm_key", token.value);
          console.log("Firebase token:", token.value);
        });
        await PushNotifications.addListener("registrationError", (token) => {
          console.log("Firebase registration error");
        });
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }

        await PushNotifications.register();
      }else {
      const permission = await Notification.requestPermission();
      if (!permission === "granted") {
        return;
      }

      getToken(this.$messaging, { vapidKey: process.env.VUE_APP_FIREBASE_TOKEN })
          .then((currentToken) => {
            if (currentToken) {
              localStorage.setItem("fcm_key", currentToken);
              // Send the token to your server and update the UI if necessary
              // ...
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      onMessage(this.$messaging, (notification) => {
        console.log(notification)
        this.notification = {
          ...notification.notification,
          data: notification.data
        }
        this.isToastOpen = true;
        (new Promise(r => setTimeout(r, 5000))).then(()=>this.isToastOpen = false);
      });
    }
    },
  },
  data() {
    return {
      festiveThemeOn: !localStorage.getItem('festive-theme-disabled'),
      isFirstHash: true,
      notification: {
        title: null,
        body: null,
        data: {}
      },
      notificationToastButtons:[
        {
          text: this.$i18n?.t('Show'),
          side: 'end',
          handler: ()=>{
            modalController.dismiss()
            this.$router.push(this.notification.data.link+ (this.notification.data.itemId ? `?highlightItem=${this.notification.data.itemId}`:''))
          }
        }
      ],
      isToastOpen: false,
      customPopoverOptions: {
        cssClass: 'langpopupclass',
      },
      version: appConfig.version,
      back: arrowBack,
      syncIcon: syncOutline,
      warningIcon: warningOutline,
      hasBackButton: this.$route.meta.hasBackButton ? true : false,
      isAndroid: true
    }
  },
  async mounted() {
    this.registerNotifications()
    this.isAndroid = await isPlatform('cordova')
    useBackButton(-1, () => {
      if (!this.$router.options.history.state.back) {
        App.exitApp();
      }
    });
    this.$store.commit('app/storeDarkMode')
    document.title = appConfig.pageTitlePrefix;
    this.applyDarkMode()
    navigator.connection.onchange = (event) => {
      if(this.$store.getters['app/getBackgroundSyncStatus']=== 'error'){
        this.doInitialSync()
      }
    }
    if(this.$route.query.access_token && this.$route.query.xuserid !== this.$store.getters['user/getUser'].uuid){
      await this.presentUserChangeAlert()
    } else {
      await this.doInitialSync()
    }
    setInterval(()=> {
      if(this.$store.getters['user/getUser'].email){
        this.checkAPIAvailability()
      }
    }, 15000);

  },
  computed: {
    currentFestiveThemeComputed(){
      const currentDate = new Date(Date.now())
      if((currentDate.getMonth() === 9 && currentDate.getDate()>=15) || (currentDate.getMonth() === 10 && currentDate.getDate() <= 3)){
        return 'halloween'
      }
      return ''
    },
    currentFestiveThemeClassComputed(){
      const festiveName = this.currentFestiveThemeComputed
      if (!festiveName) { return '' }
      console.log(festiveName)
      const className = 'festive-theme-' + festiveName + (this.festiveThemeOn?'':'-disabled')
      return {[className]: true}
    },
    title() {
      return this.$store.getters['app/getTitle'] || this.$route.matched[this.$route.matched.length - 1].meta.title || ''
    },
    darkMode() {
      return this.$store.getters['app/getDarkMode']
    },
    darkModeIcon() {
      let icon;
      if (this.darkMode === 'auto')
        icon = contrast
      else if (this.darkMode === 'true')
        icon = moon
      else if (this.darkMode === 'false')
        icon = sunny
      this.applyDarkMode()
      return icon
    },

  },
});
</script>

<style lang="scss">
ion-item,
ion-card,
ion-list:not(.suggestion-list) {
  contain: initial !important;
  overflow: initial !important;
  z-index: 1;
}

@import "./styles/main";

#toprightmenu {
  ion-button {
    margin-right: 15px;
  }
}
.item-native {
  background-color: red;
}
.sync-item {
  position: fixed;
  z-index: 10;
  margin-top: auto;
  bottom: 5px;

  ion-label {
    display: flex !important;
    align-items: center;

    * {
      margin-right: 9px;
    }
  }
}

.sync-spinner {
  transform-origin: 9px 9px;
  font-size: 18px;
  animation: sync-spinner 1.2s linear infinite;
}

@keyframes sync-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes highlight-item {
  from {
    --background: rgb(140, 130, 56);
  }
  to {
    --background: var(--ion-item-background);
  }
}
</style>

<style>
ion-loading.custom-loading {
  ion-spinner {
    transform: scale(1.5);
  }
  .loading-wrapper {
    padding: 30px;
    font-size: 1rem;
  }
  --background: #e3edff;
  --spinner-color: #1c6dff;
  padding: 80px;
  color: #1c6dff;
}
.festive-theme-halloween {
  ion-toggle::part(handle) {
    background-color: unset;
    transform: scale(1.5);
    background-size: cover;
    --handle-box-shadow: unset;
    background-image: url("/public/festive-theme-assets/halloween/pumpkin-toggle.png");
  }
  ion-slides {
    background-repeat: repeat-x;
    background-image: url("/public/festive-theme-assets/halloween/pumpkin-background.png") !important;
  }
  ion-menu ion-content::part(background) {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: contain;
    background-image: url("/public/festive-theme-assets/halloween/happy-halloween.png");
  }
  .progress-indicator-card:has(.progress) {
    background-position-x: right;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/public/festive-theme-assets/halloween/skeleton-background.png");
  }
  ion-card {
    border-image: url("/public/festive-theme-assets/halloween/bone-border.png") 35% repeat !important;
    border-image-width: 20px !important;
    border-image-outset: 3px !important;
    border-image-repeat: round !important;
  }
  .festive-theme-toggle-item::part(native) {
    border: dashed orange 2px;
    border-radius: 10px;
  }
}
.festive-theme-halloween-disabled {
  .festive-theme-toggle-item {
    &::part(native) {
      border: dashed orange 2px;
      border-radius: 10px;
    }
    ion-toggle::part(handle) {
      background-color: unset;
      transform: scale(1.5);
      background-size: cover;
      --handle-box-shadow: unset;
      background-image: url("/public/festive-theme-assets/halloween/pumpkin-toggle.png");
    }
  }
}
</style>
