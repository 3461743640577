// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging,  } from "firebase/messaging";
//import 'firebase/firebase-messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_ctli0tHDdF1RNjMmSHuVgrmJfVJBtT0",
  authDomain: "rbms-fuse.firebaseapp.com",
  projectId: "rbms-fuse",
  storageBucket: "rbms-fuse.appspot.com",
  messagingSenderId: "634761994252",
  appId: "1:634761994252:web:d7b5bf69bfce5944b64e29"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app);
//console.log(messaging.getToken({ vapidKey: firebaseConfig.apiKey }))

export default messaging
