export const mixin = {

  /**
   * Create preview from currency Object
   * @param data Currency entity 
   * @returns {string}
   */
  currencyPreview(data: any){
    return (data.prefix || "")
          + (1234.56789/1).toFixed(data.decimals).replace('.', data.decimal_point).toString().replace(/\B(?=(\d{3})+(?!\d))/g, data.thousands_separator || "")
          + (data.suffix || "")
  }
}