import { dataServiceQuery, configWithAuth } from '@/store/api_configs'
import IndexedDb from '@/utils/WorksheetDB'
import axios from 'axios'
import { STATUS } from "@/repository/Contants";

/**
 * OfflineChargerItem class manages data about
 * charger type worksheet items (download, upload, save, load)
 * and provide data structure for uniform handling
 */
export default class OfflineChargerItem {

    indexedDb = null // stores IndexedDb object
    id = null
    worksheetId = null
    chargerItemData = {}
    examinedProperties = {}
    parts = null
    worksheetObjectReference = null
    temporaryOfflineChargerItem =false

    /**
     * Initialize Object
     * @param {int} id  Id of worksheet item
     * @param {int} worksheetId Id of worksheet
     * @param {Object} chargerData Charger data from list view. If not provided needs to load or download the data manually.
     * @param {IndexedDb} indexedDb IndexedDB object for offline data management. Auto generated if not set,
     * but recommended to use one for all object, because multiple transactions can cause bad things.
     * @param {Object} worksheetObject Optional reference to worksheet includes this item.
     */
    constructor(id, worksheetId, chargerData, indexedDb = null, worksheetObject){
      this.worksheetObjectReference = worksheetObject
        this.worksheetId = worksheetId
        this.chargerItemData = chargerData
        this.id = id
        if(this.id<0){
          this.temporaryOfflineChargerItem = true
        }
        if(chargerData){
            if(chargerData.parts){
                this.importParts(chargerData.parts)
            }
            if(chargerData.examinedProperties){
                this.examinedProperties = Object.assign({},chargerData)
                this.examinedProperties.examinedProperties.forEach(prop=>{
                  (prop.problems || []).forEach(problem=>{
                      if(!problem.selected.title){
                        Object.assign(problem.selected,prop.property.possibleProblems.filter((possibleProblem)=> possibleProblem.id === problem.selectedId)[0])
                      }
                      if(problem.operation_complete === undefined){
                        problem.operation_complete = problem.selected.operation_complete
                      }
                    })
                })
                delete this.examinedProperties.charger
                delete this.chargerItemData.examinedProperties
            } else {
              this.examinedProperties = Object.assign({},chargerData)
            }
        }
        if (indexedDb)
            this.indexedDb = indexedDb
        else
            this.indexedDb = new IndexedDb()
    }
    /**
     * Returns if charger item is able to edit
     * @returns {boolean}
     */
    calculateIfEditAllowed(){
      return !this.examinedProperties.is_finished &&
      this.worksheetObjectReference.worksheetData.preparedData.status < STATUS.CLOSED &&
      (this.isOwnWorksheet()
      || JSON.parse(localStorage.getItem('service_app_user')).abilities['access-sysadmin'])
    }
    isOwnWorksheet(){
      return !this.worksheetObjectReference || this.worksheetObjectReference.worksheetData.preparedData.userAssignment.some(user=>JSON.parse(localStorage.getItem('service_app_user')).__uid === user.user.id)
    }
    /**
     * Map provided parts array and put it into global parts property in the correct format
     * @param {Object} parts Raw parts array from server
     */
    importParts(parts){
        this.parts = parts.map((item)=>{
            const newPartObject = {...item.part, ...item};
            newPartObject.qty = parseInt(newPartObject.qty||1)
            newPartObject['part_id'] = item.part.id
            newPartObject.id = item.id
            delete newPartObject.part;
            return newPartObject;
        })
    }
    /**
     * Transform parts data back to the original format and return it. Result is processable for api only.
     * @returns
     */
    getPartsForUpload(){
        const partsPostData = []
        for (let i = 0; i < (this.parts || []).length; i++) {
          const item = this.parts[i];
          partsPostData.push({
            id: item.id || null,
            'part_id': item['part_id'],
            warehouse_id: item.warehouse_id,
            qty: item.qty,
            part: {...item, id: item['part_id']}
        })
        }
        return partsPostData
    }
    /**
     * Download base data about charger form server
     */
    async downloadChargerItemData(){
        const downloadData = await axios(dataServiceQuery("worksheet", "get-chargers", Math.abs(this.worksheetId)))
        this.chargerItemData = downloadData.data.data.find((item=>item.id === this.id))
    }
    /**
     * Download examined properties from server
     */
    async downloadExaminedProperties(){
        this.examinedProperties = (await axios(dataServiceQuery("worksheet", "getExaminedBatteryPropsByWorksheetItem", this.id))).data.data
    }
    /**
     * Download picture data about charger problems (not pictures just url-s)
     */
    async downloadProblemImages(){
      const examinedProperties = (await axios(dataServiceQuery("worksheet", "getProblemImagesByWorksheetItem",{query: this.id}))).data.data
      console.log(examinedProperties)
      this.examinedProperties.problemImages = examinedProperties
    }
    /**
     * Load all available data about charger from IndexedDB
     */
    async loadChargerItemData(){
        const response = await this.indexedDb.getItem("batteries", this.id)
        response.item = JSON.parse(response.item)
        this.examinedProperties = response.item.examinedProperties
        this.chargerItemData = response.item.chargerItemData
        this.parts = response.item.parts || []
    }
    /**
     * Store Object's current state into IndexedDB
     */
    async saveChargerItemData(){
        await this.indexedDb.storeItem("batteries",
                JSON.stringify({parts: this.parts,measurements: this.measurements, examinedProperties: this.examinedProperties, chargerItemData: this.chargerItemData}),
                this.id, {worksheetId: this.worksheetId})
        console.log("Charger item data saved");
    }
    /**
     * Collect not uploaded pictures and upload all
     * @param {boolean} syncData Download fresh data after upload
     */
    async uploadPictures(syncData = true){
        if(!this.examinedProperties || !this.examinedProperties.examinedProperties){return;}
        this.examinedProperties.examinedProperties.forEach((exProp=>{
            (exProp.problems || []).forEach(async (problem)=>{
                /*if(!problem.imageData || problem.imageData.startsWith('http')) { return }
                const response = await axios(configWithAuth('POST','image-manager/upload-problem-image',
                    {
                        worksheetItemId: this.id,
                        file: problem.imageData,
                        problemId: problem.selected.id || null,
                    }
                ))
                if(response.data.success) {problem.imageData = response.data.data}*/
                (problem.imagesData || []).forEach(async image=>{
                  const response = await axios(configWithAuth('POST','image-manager/upload/worksheet/problem-image',
                    {
                        worksheetItemId: this.id,
                        file: image,
                        problemId: problem.id || null,
                    }
                  ))
                  if(response.data) {problem.imagesData.splice(problem.imagesData.indexOf(image),1)}
                })
            })
        }))
        if(syncData){
          this.downloadProblemImages()
        }
    }
    /**
     * Transform and upload parts used on this item (not saves assignments for problems)
     */
    async uploadParts(){
        const partsPostData = this.getPartsForUpload()
        const response = await axios(configWithAuth('POST','worksheets/save-parts',
            {
                worksheetItemId: this.id,
                parts: partsPostData
            }
        ))
        if(response.data.success){
            this.importParts(response.data.data)
        }
    }
    /**
     * Upload examined properties and download the fresh data from server
     * @returns Server's response
     */
    async uploadExaminedProperties(){
        await this.uploadParts()
        return await axios(configWithAuth('POST','worksheets/save-examined-properties',{
          data: {
            ...this.examinedProperties,
            dataTableImage: this.chargerItemData.dataTableImage
          }
        }))
    }
    /**
     * Delete all data from IndexedDB
     */
    async deleteLocalData(){
        await this.indexedDb.deleteItem(this.id,'batteries')
    }
    /**
     * Upload and then download all data
     */
    async syncData(){
        if(!this.chargerItemData.modified){return}
        await this.saveChargerItemData()
        await this.uploadExaminedProperties()
        await this.uploadPictures()
        await this.downloadChargerItemData()
        this.chargerItemData.modified = false
        this.examinedProperties.forceUpdate = false
    }
    /**
     * Mark charger as not found and save data locally
     */
    async toggleItemNotFound(){
        this.examinedProperties['device_not_found'] = !this.examinedProperties['device_not_found']
        this.saveChargerItemData()
        this.chargerItemData.modified = true

        this.examinedProperties.forceUpdate = true
        /*this.$http(configWithAuth('post','worksheets/toggle-device-not-found',{id: this.id})).then(async response =>{
            if(response.data.success){
                this.chargerItemData['device_not_found'] = !this.chargerItemData['device_not_found']
                this.saveChargerItemData()
            }
        }).catch(async () => {
            this.chargerItemData['device_not_found'] = !this.chargerItemData['device_not_found']
            this.saveChargerItemData()
        })*/
    }
    /**
     * Calculates how many examined property has checked
     * @returns {int} The index of last passed property
     */
    getPassedIndex(){
        let passedIndex = 0
        for (const element of this.examinedProperties.examinedProperties){
            if(element['is_examined'])
                passedIndex += 1
            else
                break
        }
        return passedIndex
    }
}
