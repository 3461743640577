import WorksheetDB from '@/utils/WorksheetDB'
import axios from 'axios';
import { dataServiceQuery, configWithAuth } from '../api_configs';

export default {
    namespaced: true,
    state: {
        rememberMe: localStorage.getItem('rememberme') || "",
        darkMode: localStorage.getItem('darkMode') || "auto",
        title: '',
        backgroundSyncStatus: 'pending',
        backgroundSyncPromise: null,
        supplementaryData: {
          userList: null,
          vehicleList: null,
          createBattery: null,
          createCharger: null
        }
     },
     actions:{
      async downloadAndStoresupplementaryData(state){
        state.state.backgroundSyncPromise = new Promise((resolve)=> {
          const db = new WorksheetDB()
          db.getAll('supplementary-data').then(async supplementaryArray=>{
            //console.log(supplementaryArray)
            if(supplementaryArray && supplementaryArray.length>0){
              Object.keys(state.state.supplementaryData).forEach(async key=>{
                state.state.supplementaryData[key] = JSON.parse(supplementaryArray.find(item=> item.keyName === key).item)
              })
            }
            try {
              state.state.supplementaryData.userList = (await axios(dataServiceQuery("user", "getAllOwnerUsersWithVehicle",{}))).data.data
              state.state.supplementaryData.vehicleList = (await axios(dataServiceQuery("vehicle", "getAllWithWarehouseAndStock",{}))).data.data
              state.state.supplementaryData.createBattery = (await axios(configWithAuth("get", "batteries/create",{}))).data.data
              state.state.supplementaryData.createCharger = (await axios(configWithAuth("get", "chargers/create",{}))).data.data
              state.state.supplementaryData.serviceList = (await axios(dataServiceQuery("product", "lookupServicesOnlySimplified",{}))).data.data
              Object.keys(state.state.supplementaryData).forEach(key=>{
                if(supplementaryArray.length === 0){
                  db.storeItem('supplementary-data',JSON.stringify(state.state.supplementaryData[key]),null,{keyName:key})
                } else {
                  //console.log(supplementaryArray.find(item=> item.keyName === key).id)
                  db.updateItem('supplementary-data',JSON.stringify(state.state.supplementaryData[key]),supplementaryArray.find(item=> item.keyName === key).id,{keyName:key})
                }
              })
              resolve()
            } catch (error) {
              console.log('Failed to update offline supplementary data for worksheets:')
              //console.log(error)
              resolve()
            }
          })

        })
        return await state.state.backgroundSyncPromise
      },
      async getSupplementaryData(store){
        if(!(store.state.supplementaryData && store.state.supplementaryData.userList && store.state.supplementaryData.userList.length > 0)){
          if(store.state.backgroundSyncStatus === 'pending'){
            await store.dispatch("downloadAndStoresupplementaryData")
          }
        }
        return store.state.supplementaryData
      }
     },
    mutations: {
      /**
       * Change default title on page's toolbar
       * @param state Store's state
       * @param newTitle Title to set
       */
      setTitle(state, newTitle){
        state.title = newTitle
      },
      setBackgroundSyncStatus(state, newStatus){
        state.backgroundSyncStatus = newStatus
      },
      setDarkMode(state, newState){
          state.darkMode = newState
          localStorage.setItem('darkMode', newState)
      },
      setDarkModeWithoutSave(state, newState){
          state.darkMode = newState
      },
      storeDarkMode(state){
        localStorage.setItem('darkMode', state.darkMode)
      }
    },

    getters: {
        getSupplementaryData(state){
          return state.supplementaryData
        },
        getRememberMe(state){
            return state.rememberMe
        },
        getTitle(state){
            return state.title
        },
        getDarkMode(state){
            return state.darkMode
        },
        getBackgroundSyncStatus(state){
          return state.backgroundSyncStatus
        }
     }
  }
