import {ref} from "vue";

const locationTags = ref([])

export function useDeviceLocationTags() {

    function setLocationTags(tags) {
        locationTags.value = tags
    }

    return {
        locationTags,
        setLocationTags
    }
}