import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from "../store";



 
const routes = [
  {
      path: '/',
      redirect: '/app/controlpanel'
  },
  {
    path: '/auth/',
    component: () => import('@/views/layouts/AuthLayout.vue'),
    props: true,
    meta: {
        guestOnly: true
    },
    children: [
        {
            path: 'login',
            component: () => import('@/views/auth/Login.vue'),
            meta: {
                guestOnly: true,
                title: "Login"
            }
        },
        {
            path: 'logout',
            component: () => import('@/views/auth/Logout.vue'),
            meta: {
                guestOnly: false,
                title: "Logout"
            },
        },
    ]
},
{
  path: '/app/',
  component: () => import('@/views/layouts/ApplicationLayout.vue'),
  props: true,
  meta: {
      guestOnly: false
  },
  children: [
    {
        path: 'controlpanel',
        component: () => import('@/views/tabs/ControlPanel.vue'),
        meta: {
            guestOnly: false,
            title: "Control panel"
        }
    },
    {
        path: 'worksheets2',
        component: () => import('@/views/tabs/WorksheetsV2.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheets"
        }
    },
    {
        path: 'inner-worksheet-manager',
        component: () => import('@/views/tabs/SiteWorksheetManager.vue'),
        meta: {
            guestOnly: false,
            title: "Site Worksheet Manager"
        }
    },
    {
        path: 'myworkhseets2',
        component: () => import('@/views/tabs/WorksheetsV2.vue'),
        meta: {
            myWorksheetsOnly: true,
            guestOnly: false,
            title: "My worksheets"
        }
    },
    /*{
        path: 'worksheets',
        component: () => import('@/views/tabs/Worksheets.vue'),
        meta: {
            guestOnly: false,
            title: "Open worksheets"
        },
    },
    {
        path: 'myworksheets',
        component: () => import('@/views/tabs/MyWorksheets.vue'),
        meta: {
            guestOnly: false,
            title: "Open worksheets"
        },
    },*/
    {
        path: 'downloadapps',
        component: () => import('@/views/tabs/DownloadApps.vue'),
        meta: {
            guestOnly: true,
            title: "Download apps"
        },
    },
    {
        path: 'offline/worksheets',
        component: () => import('@/views/tabs/OfflineWorksheets.vue'),
        meta: {
            guestOnly: false,
            title: "Offline worksheets"
        },
    },
    {
        path: 'worksheet/:worksheetId',
        component: () => import('@/components/forms/worksheets/components/BaseData/BaseData.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheet",
            hasBackButton: true
        },
    },
    {
        path: 'groupmesurement/:worksheetId',
        component: () => import('@/components/forms/MeasureBattery/GroupMeasurement/GroupMeasurements.vue'),
        meta: {
            guestOnly: false,
            title: "Group measurement",
            hasBackButton: true
        },
    },
    {
        path: 'worksheet/:worksheetId/batteries',
        component: () => import('@/components/forms/worksheets/lists/batteries/WBatteries.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheet",
            hasBackButton: true
        },
    },
    {
        path: 'worksheet/:worksheetId/refillonlybatteries',
        component: () => import('@/components/forms/worksheets/lists/RefillOnlyBatteryList.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheet",
            hasBackButton: true
        },
    },
    {
        path: 'worksheet/:worksheetId/review',
        component: () => import('@/components/forms/worksheets/worksheetReview/WorksheetReview.vue'),
        meta: {
            guestOnly: false,
            title: "Review",
            hasBackButton: true
        },
    },
    {
        path: 'worksheet/:worksheetId/finish',
        component: () => import('@/components/forms/worksheets/worksheetReview/WorksheetReview.vue'),
        meta: {
            guestOnly: false,
            title: "Finish worksheet",
            hasBackButton: true
        },
    },
    {
        path: 'worksheet/:worksheetId/chargers',
        component: () => import('@/components/forms/worksheets/lists/Chargers.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheet",
            hasBackButton: true
        },
    },
    {
        path: 'batteries',
        component: () => import('@/views/tabs/Batteries.vue'),
        meta: {
            guestOnly: false,
            title: "Batteries"
        },
    },
    {
        path: 'chargers',
        component: () => import('@/views/tabs/Chargers.vue'),
        meta: {
            guestOnly: false,
            title: "Chargers"
        },
    },
    {
        path: 'measurebattery/:batteryId',
        component: () => import('@/components/forms/MeasureBattery/MeasureBattery.vue'),
        meta: {
            guestOnly: false,
            title: "Measure battery",
            hasBackButton: true
        },
    },
    {
        path: 'examinecharger/:chargerId',
        component: () => import('@/components/forms/ExamineCharger/ExamineCharger.vue'),
        meta: {
            guestOnly: false,
            title: "Examine charger",
            hasBackButton: true
        },
    },
    {
        path: 'batteries/:batteryId',
        component: () => import('@/components/forms/batteries/EditBatteryForm.vue'),
        meta: {
            guestOnly: false,
            title: "Add battery",
            hasBackButton: true
        },
        
    },
    {
        path: 'test',
        component: () => import('@/views/tabs/TestComponents.vue'),
        meta: {
            guestOnly: false,
            title: "Test comonents",
            hasBackButton: false
        },
        
    },
    {
        path: 'test/externaldevice',
        component: () => import('@/views/tabs/ExternalReaderTester.vue'),
        meta: {
            guestOnly: false,
            title: "Test external device",
            hasBackButton: false
        },
        
    },
    {
        path: 'parts/:batteryId',
        component: () => import('@/components/forms/MeasureBattery/parts/WorksheetUsedParts.vue'),
        meta: {
            guestOnly: false,
            title: "Worksheet used parts",
            hasBackButton: false
        },
        
    },
  ]
},

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
    //Restore default toolbar title
    store.commit('app/setTitle', '')
    
    if (to.fullPath == from.fullPath){
        next(false)
        return
    }
    //Start load popup
    //Must be stopped in each component
    if(to.path !== from.path){
        await store.dispatch('load/startLoad')
    }
    
    let isLoggedIn
    if(store.getters['user/getUser'].token){
        isLoggedIn = true
    }else {
        isLoggedIn = false
    }
    
    //If not matched any route
    if(to.matched.length === 0) {
        if (isLoggedIn)
            next('/app/controlpanel')
        else
            next('/auth/login')
        await store.dispatch('load/stopLoad')
        return
    }
    
    //Can't login if logged in
    if (to.fullPath == '/auth/login' && isLoggedIn){
        next('/app/controlpanel')
    } else
    //Can't logout if not logged in
    if (to.fullPath == '/auth/logout' && isLoggedIn){
        store.dispatch('auth/logout')
        next(false)
        return
    } else
    //Check if a page is guestonly
    if (to.matched.some(record => record.meta.guestOnly)) {
      next()
    } else {
      if (isLoggedIn || (to.query.access_token && to.query.xuserid)) {
        next()
        return
      }
      next('/auth/login')
  }
})




export default router
