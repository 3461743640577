
import i18n from "@/i18n";import { alertController  } from '@ionic/vue';

/**
 * Creates and return error dialog (not show, just create)
 * @param message Message shown in dialog body
 * @param retryAction Method to run if push retry button
 * @returns {HTMLIonAlertElement}
 */
export async function createErrorDialogWithOptions(message, retryAction, okAction) {
    const buttons = []
    if (retryAction){
      buttons.push({
        text: i18n.global.t('Retry'),
        handler: () => {
            retryAction()
        },
      },)
    }
    buttons.push({
        text: i18n.global.t('Okay'),
        handler: () => {
          if(okAction) okAction()
        },
    },)
    const loading = await alertController 
      .create({
        cssClass: 'my-custom-class',
        header: i18n.global.t('Error'),
        subHeader: '',
        message: message,
        buttons: buttons
      });
    return loading
  }
