import type {DirectiveBinding} from "vue";

/**
 * Update binding method
 *
 */
/*const updateBinding = (first_name: string, last_name: string): string => {
    return `${first_name} ${last_name}`
}*/
const getInputValueFromHtmlElement = (el: HTMLElement):string=>{
    const inputElements = el.getElementsByTagName('ion-input')
    if(inputElements.length>0){
        return el.getElementsByTagName('ion-input')[0].value as string
    }
    return ''
}
const setInputValueFromHtmlElement = (el: HTMLElement, value)=>{
    const inputElements = el.getElementsByTagName('ion-input')
    if(inputElements.length>0){
        el.getElementsByTagName('ion-input')[0].value = value
    }
}
const updateValue = (el: HTMLElement)=>{
    const modelValue = getInputValueFromHtmlElement(el)
    if(typeof modelValue !== typeof ''){return;}
    setInputValueFromHtmlElement(el, modelValue.replace('-',''))
}

export const acceptOnlyPositiveNumbersDirective = {

    /**
     * Created hook
     *
     * @param el - HTML element
     * @param binding - Binding
     * @return void
     */
    created(el: HTMLElement): void {
        updateValue(el)
    },

    /**
     * Updated hook
     *
     * @param {HTMLElement} el - HTML element
     * @param {DirectiveBinding} binding - Binding
     * @return void
     */
    updated(el: HTMLElement): void {
        updateValue(el)
    }

}