
import { createErrorDialogWithOptions } from "@/store/error_dialog";
import i18n from "@/i18n";
//import { alertController  } from '@ionic/vue';

export default {
    namespaced: true,
    state: {
        errorController: null,
     },
    mutations: {

      },
    actions: {
        /**
         * (Re)create and show error dialog
         * @param param0 Auto passed store things
         * @param payload {
         *    message - Message in dialog body
         *    retryAction - Method to run if user push retry button
         *   }
         */
         async showErrorDialog({state, dispatch}, payload){
            if(!state.errorController){
                const resp = await createErrorDialogWithOptions(payload.message, payload.retryAction, payload.okAction)
                await resp.present();
                state.errorController = resp
            } else {
                await dispatch("destroyErrorDialog")
                await dispatch("showErrorDialog", payload)
            }

        },
        async showAxiosErrorDialog({state, dispatch}, payload){
          console.log(payload)
            const error = payload.error
            if(error && error.response && error.response.status && error.response.status === 401){ return;}
            let errorMessage;
            if ( error && !payload.message) {
                if (error.response.status === 422) {
                    const errors = error.response.data.errors
                    if (errors) {
                        const field = Object.keys(errors)[0]
                        errorMessage = errors[field]
                    } else {
                        errorMessage = error.response.statusText
                    }
                    errorMessage = i18n.global.t("Validation error: ") + (errorMessage || "UNKNOWN ERROR")
                } else {
                    if (error.response.statusText) {
                        errorMessage = error.response.statusText
                        if(error.response.status){
                            errorMessage += ` (${error.response.status})`
                        }
                    } else if(error.message){
                        errorMessage = error.message
                    } else {
                        errorMessage = i18n.global.t('Unknown error')
                    }
                }
            }
            if(!state.errorController){
                const resp = await createErrorDialogWithOptions(payload.message || errorMessage, payload.retryAction, payload.okAction)
                await resp.present();
                state.errorController = resp
            } else {
                await dispatch("destroyErrorDialog")
                await dispatch("showErrorDialog", payload)
            }

        },
        /**
         * Destroy error dialog if it exist.
         * @param param0 Auto passed store things
         */
        async destroyErrorDialog({state}){
            if(state.errorController){
                await state.errorController.dismiss()
                state.errorController = null
            }

        },
    },
    getters: { }
  }
