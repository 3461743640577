import OfflineWorksheet from '@/repository/OfflineWorksheet'
import IndexedDb from '@/utils/WorksheetDB';

export default {
    namespaced: true,
    state: {
        worksheets: {}
     },
    actions: {
      async deleteAllLocally(store){
        window.onbeforeunload = null
        store.state.worksheets = await store.dispatch('getWorksheetsWithBasicData')
        const itemsCount = Object.values(store.state.worksheets).length
        for (let i = 0; i < itemsCount; i++) {
          const worksheet = Object.values(store.state.worksheets)[0];
          await worksheet.deleteWorksheetLocalData()
          if(worksheet.closeDb){
            worksheet.closeDb()
          }
          delete store.state.worksheets[worksheet.id]
        }
        store.state.worksheets = {}
      },
      async getActiveWarehouses(store, worksheetId){
        if(!store.state.worksheets[worksheetId] || !store.state.worksheets[worksheetId].workTimes){return null}
        const warehouses = []
        store.state.worksheets[worksheetId].workTimes.forEach(workTime=>{
          if(!workTime.vehicles){ return }
          workTime.vehicles.forEach(vehicle => {
            if(!vehicle.warehouse){ return }
            if(!warehouses.find(warehouse=> warehouse.id === vehicle.warehouse.id)){
              warehouses.push(vehicle.warehouse)
            }
          })
        })
        if(store.state.worksheets[worksheetId].worksheetData.preparedData.site?.is_own_site){
          const supplementaryData = (await store.dispatch("app/getSupplementaryData", null,{root: true}))
          supplementaryData.vehicleList.forEach(vehicle=>{
            if(vehicle.warehouse?.is_local) {warehouses.push(vehicle.warehouse)}
          })
        }
        console.log(warehouses)
        return warehouses
      },
      async getWarehouses(store){
        const supplementaryData = (await store.dispatch("app/getSupplementaryData", null,{root: true}))
        const warehouses = []
        supplementaryData.vehicleList.forEach(vehicle=>{
          if(vehicle.warehouse) {warehouses.push(vehicle.warehouse)}
        })
        return warehouses
      },
      /**
       * Close database connections and unload worksheet data from memory
       * @param {Object} state Auto passed by vuex
       */
        async deleteAllWorksheetDataFromMemory(state){
          window.onbeforeunload = null
          Object.values(state.state.worksheets).forEach((worksheet)=> {
            if(worksheet.indexedDb){
              worksheet.indexedDb.closeDb()
            }
          })
          state.state.worksheets = {}
        },
        /**
         * Get reference to a worksheet from memory by id.
         * (load from IndexedDB if not loaded yet)
         * @param {Object} state Auto passed by vuex
         * @param {number|string} id Worksheet id
         * @returns Requested worksheet
         */
        async getWorksheet(state,payload){
          let id = null
          let recursive = false
          if(typeof payload !== typeof {}){
            id = payload
          }else {
            id = payload.id
            recursive = payload.recursive
          }
          if(state.state.worksheets[recursive ? (Math.abs(id)*-1) : id])
              return state.state.worksheets[recursive ? (Math.abs(id)*-1) : id]
          const newWorksheet = new OfflineWorksheet(id, null, recursive)
          await newWorksheet.loadFullWorksheet()
          state.state.worksheets[newWorksheet.id] = newWorksheet
          return newWorksheet
        },
        async getWorksheetIfAvailable(state,payload){
          const id = payload
          state.state.worksheets = await state.dispatch('getWorksheetsWithBasicData', id)
          if(state.state.worksheets[id]){
            await state.state.worksheets[id].loadFullWorksheet()
            return state.state.worksheets[id]
          } else {
            return null
          }
        },
        /**
         * Download and store a worksheet by id
         * @param {Object} state Auto passed by vuex
         * @param {number|string} id Worksheet id
         * @returns Requested worksheet
         */
        async downloadAndSaveWorksheet(state,{id,recursive}){
            if(state.state.worksheets[id])
                return state.state.worksheets[id]
            const newWorksheet = new OfflineWorksheet(id, null, recursive)
            await newWorksheet.downloadAndStoreFullWorksheet(recursive)
            state.state.worksheets[id] = newWorksheet
            return newWorksheet
        },
        /**
         * Load and return offline worksheet list.
         * (not loads measurements and examined properties for worksheet items)
         * @param {Object} state Auto passed by vuex
         * @returns Worksheet list
         */
        async getWorksheetsWithBasicData(state){
            const indexes = await (new IndexedDb()).getIndexes("worksheets")
            let worksheet
            for(let i = 0;i <indexes.length;i++){
                //console.log(!(state.state.worksheets[indexes[i]] instanceof Object))
                if(!(state.state.worksheets[indexes[i]] instanceof Object)){
                    worksheet = new OfflineWorksheet(indexes[i])
                    await worksheet.loadWorksheetData()
                    await worksheet.loadWorksheetItems()
                    state.state.worksheets[indexes[i]] = worksheet
                }
            }
            return state.state.worksheets
        },
        /**
         * Get worksheet item by id
         * @param {Object} state Auto passed by vuex
         * @param {number|string} id Worksheet item id
         * @returns Requested worksheet item
         */
        async getWorksheetItem(state, id){
            state.state.worksheets = await state.dispatch('getWorksheetsWithBasicData', id)
            const indexes = Object.keys(state.state.worksheets)
            for(let i = 0;i < indexes.length;i++){
                for(let n = 0;n < state.state.worksheets[indexes[i]].batteryItems.length;n++){
                    if(state.state.worksheets[indexes[i]].batteryItems[n].id === parseInt(id)){
                        const result = state.state.worksheets[indexes[i]].batteryItems[n]
                        if(!result.examinedProperties.examinedProperties)
                            await result.loadBatteryItemData()
                        return result
                    }
                }
                for(let n = 0;n < state.state.worksheets[indexes[i]].chargerItems.length;n++){
                    if(state.state.worksheets[indexes[i]].chargerItems[n].id === parseInt(id)){
                        const result = state.state.worksheets[indexes[i]].chargerItems[n]
                        if(!result.examinedProperties.examinedProperties)
                            await result.loadChargerItemData()
                        return result
                    }
                }
            }
        },
        /**
         * Get all raw battery item data from database
         * @returns Array of raw worksheet items
         */
        async getAllBatteryItem(){
            const db = new IndexedDb()
            return await db.getAll('batteries')
        },
        /**
         * Get worksheet item by device id not worksheet item id
         * @param {Object} state Auto passed by vuex
         * @param {number|string} id Device id
         * @returns Requested worksheet item
         */
        async getBatteryItemByBatteryId(state, id){
          state.state.worksheets = await state.dispatch('getWorksheetsWithBasicData', id)
          const indexes = Object.keys(state.state.worksheets)
          for(let i = 0;i < indexes.length;i++){
              for(let n = 0;n < state.state.worksheets[indexes[i]].batteryItems.length;n++){
                  if(state.state.worksheets[indexes[i]].batteryItems[n].batteryItemData.battery.id === parseInt(id)){
                      const result = state.state.worksheets[indexes[i]].batteryItems[n]
                      if(!result.examinedProperties.examinedProperties)
                          await result.loadBatteryItemData()
                      return result
                  }
              }
          }
        },
        /**
         * Delete worksheet from IndexedDb and from memory
         * @param {Object} state Auto passed by vuex
         * @param {*} id Worksheet id
         */
        async deleteWorksheet(state, id){
          window.onbeforeunload = null
          state.state.worksheets = await state.dispatch('getWorksheetsWithBasicData', id)
          await state.state.worksheets[id].deleteWorksheetLocalData()
          console.log(state.state.worksheets[id])
          if(state.state.worksheets[id].closeDb){
            state.state.worksheets[id].closeDb()
          }
          delete state.state.worksheets[id]
        },
        /**
         * Unload worksheet from memory by id
         * @param {Object} state Auto passed by vuex
         * @param {number|string} id Worksheet id
         */
        unloadWorksheet(state, id){
          console.log(state.state.worksheets[id])
          if(state.state.worksheets[id].closeDb){
            state.state.worksheets[id].closeDb()
          }
            if(state.state.worksheets[id]) { delete state.state.worksheets[id] }
        }
      },
    getters: {

     }
  }
