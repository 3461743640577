import { logoAndroid, pulseOutline, extensionPuzzleOutline, batteryHalf, cashOutline, list,readerOutline, peopleOutline, logInOutline, buildOutline, keyOutline, cloudOfflineOutline, flashOutline, personOutline, documentsOutline, homeOutline, layersOutline } from 'ionicons/icons';

export const loginTabs = [ {
    title: 'Login',
    url: '/auth/login',
    iosIcon: logInOutline,
    mdIcon: logInOutline,
    selected: true,
    reqPermission: ''
  },
  {
    title: 'Download apps',
    url: '/app/downloadapps',
    iosIcon: logoAndroid,
    mdIcon: logoAndroid,
    selected: false,
    reqPermission: '',
    color: 'var(--ion-color-success)',
    backgroundColor: 'var(--ion-color-dark)',
  },]

export const appTabs = [
    {
      title: 'Control panel',
      url: '/app/controlpanel',
      iosIcon: homeOutline,
      mdIcon: homeOutline,
      selected: true,
      reqPermission: ''
    },
    {
      title: 'Nyitott munkalapjaim',
      url: '/app/myworkhseets2',
      iosIcon: personOutline,
      mdIcon: personOutline,
      selected: false,
      reqPermission: ''
    },
    {
      title: 'Összes munkalap',
      url: '/app/worksheets2',
      iosIcon: documentsOutline,
      mdIcon: documentsOutline,
      selected: false,
      reqPermission: ''
    },
    {
      title: 'Belső munkalap kezelő<br/><div style="background-color:var(--ion-color-primary-tint);width:fit-content;padding:1px 6px 1px 6px;border-radius:10px;color:black">demo</div>',
      url: '/app/inner-worksheet-manager?forceMenuClosed',
      iosIcon: layersOutline,
      mdIcon: layersOutline,
      selected: false,
      reqPermission: ''
    },
    {
      title: 'Offline worksheets',
      url: '/app/offline/worksheets',
      iosIcon: cloudOfflineOutline,
      mdIcon: cloudOfflineOutline,
      selected: false,
      reqPermission: ''
    },
    /*{
      title: 'My worksheets',
      url: '/app/myworksheets',
      iosIcon: readerOutline,
      mdIcon: readerOutline,
      selected: false,
      reqPermission: 'is-developer'
    },
    {
      title: 'Worksheets',
      url: '/app/worksheets',
      iosIcon: readerOutline,
      mdIcon: readerOutline,
      selected: false,
      reqPermission: 'is-developer'
    },*/
    {
      title: 'Service',
      selected: false,
      icon: buildOutline,
      group: [
        {
          title: 'Batteries',
          url: '/app/batteries',
          iosIcon: batteryHalf,
          mdIcon: batteryHalf,
          selected: false,
          reqPermission: ''
        },
        {
          title: 'Chargers',
          url: '/app/chargers',
          iosIcon: flashOutline,
          mdIcon: flashOutline,
          selected: false,
          reqPermission: ''
        },
      ]
    },
    {
      title: 'Test external device',
      url: '/app/test/externaldevice',
      iosIcon: pulseOutline,
      mdIcon: pulseOutline,
      selected: false,
      reqPermission: ''
    },
    {
      title: 'Test Components',
      url: '/app/test',
      iosIcon: extensionPuzzleOutline,
      mdIcon: extensionPuzzleOutline,
      selected: false,
      reqPermission: 'is-developer'
    },
    {
      title: 'Download apps',
      url: '/app/downloadapps',
      iosIcon: logoAndroid,
      mdIcon: logoAndroid,
      selected: false,
      reqPermission: '',
      color: 'var(--ion-color-success)',
      backgroundColor: 'var(--ion-color-dark)',
    },
]
