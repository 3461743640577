import { appConfig } from '@/config';
import store from './index'

/**
 * Axios config for login
 */
export const loginconfig = {
    method: 'post',
    url: appConfig.baseURL + 'auth/login',
    'headers': {
      "X-App-Module": "service",
      'X-Request-Locale': 'hu',
      'Accept': 'application/json',
    },
    'data': {
        'email': '',
        'password': '',
        fcm_token : localStorage.getItem("fcm_key") || undefined
    },
}
/**
 * Returns axios config with given parameters.
 * Authentication in header auto included if available.
 * @param method Request method
 * @param route Request route (without base url)
 * @param data Request body
 * @param params Request params
 * @returns {Object}
 */
export function configWithAuth(method, route, data, params, plusHeaders = {}, timeout){
    return {
        timeout,
        method: method,
        url: appConfig.baseURL + route,
        'headers': {
            "X-App-Module": "service",
            'X-Requested-With': 'XMLHttpRequest',
            'X-Request-Locale': localStorage.getItem("active_locale") || navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || "en",
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + (store.getters['user/getUser'].token || ''),
            'X-User-UUID': store.getters['user/getUser'].uuid,
            ...plusHeaders
        },
        'data': data,
        'params': params
    }
}
export function dataServiceQuery(entity, method, query){
    let data;
    if (typeof query === "object") {
        data = query;
    } else {
        data = {
            query: query
        }
    }
    return configWithAuth('POST', `dataservice/${entity}/${method}`, data)
}
