import i18n from "@/i18n";
import { createLoadingWithOptions } from "@/store/loading";

export default {
    namespaced: true,
    state: { 
        loadController: null,
        loadMessage: i18n.global.t("Loading app")
     },
    actions: { 
        /**
         * (Re)create and show loading popup
         * @param param0 Auto passed store things
         */
        async startLoad({state}, message){
            if(!state.loadController){
                state.loadController = "pending"
                state.loadMessage = i18n.global.t(state.loadMessage === i18n.global.t("Loading app") ? "Loading app" : "Loading")
                const resp = await createLoadingWithOptions(message ? i18n.global.t(message) : state.loadMessage)
                await resp.present();
                if (state.loadController !== "pending")
                    await resp.dismiss()
                else
                    state.loadController = resp
            } 
            
        },
        /**
         * Stop and destroy loading popup if exist
         * @param param0 Auto passed store things
         */
        async stopLoad({state}){
            if(state.loadController === "pending"){
                state.loadController = null
                return
            }
            if(state.loadController){
                await state.loadController.dismiss()
                state.loadController = null
            }
            
        },
        /**
         * Change message in component if loading popup exist and visible
         * @param param0 Auto passed store things
         * @param message New message to show
         */
        async changeMessage({state},message){
            if(state.loadController){
                state.loadMessage = message
                if(state.loadController !== "pending")
                    state.loadController.message = message
            }
        }
    },
  }