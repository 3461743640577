import type {DirectiveBinding} from "vue";



export const longpressDirective = {

    /**
     * Created hook
     *
     * @param el - HTML element
     * @param binding - Binding
     * @return void
     */
    created(el: HTMLElement, binding: DirectiveBinding ): void {
        // Make sure expression provided is a function
        if (typeof binding.value !== 'function') {
            // Fetch name of component
            // pass warning to console
            const warn = `[longpress:] provided expression is not a function, but has to be`

            console.warn(warn)
        }

        // Define variable
        let pressTimer = null as number|null

        // Define funtion handlers
        // Create timeout ( run function after 1s )
        const start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return;
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    // Run function
                    console.log(binding.value)
                    if(navigator.vibrate){
                        navigator.vibrate(300)
                    }
                    handler(e)
                }, 1000)
                console.log(pressTimer)
            }
        }

        // Cancel Timeout
        const cancel = (e) => {
            // Check if timer has a value or not
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        // Run Function
        const handler = (e) => {
            binding.value(e)
        }

        // Add Event listeners
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        // Cancel timeouts if this events happen
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    },

}