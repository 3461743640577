import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import axios from 'axios';
import VueSignaturePad from 'vue-signature-pad';

/* Theme variables */
import './styles/theme/variables.css';

import store from './store'
import i18n from './i18n'
import './registerServiceWorker';
import { fullNameDirective } from './directives/FullnameDirective';
import { longpressDirective } from './directives/LongPressDirective';
import { markNegativeNumbersDirective } from './directives/MarkNegativeNumbersDirective';
import { acceptOnlyNumbersDirective } from './directives/AcceptOnlyNumbersDirective';
import { acceptOnlyPositiveNumbersDirective } from './directives/AcceptOnlyPositiveNumbersDirective';
import { tooltip } from '@ionited/tooltip-vue';

import firebaseMessaging from './firebase'

const app = createApp(App).use(i18n).use(store)
  .use(IonicVue)
  .use(router)
  .use(VueSignaturePad)
  .directive('fullname', fullNameDirective)
  .directive('longpress', longpressDirective)
  .directive('marknegativenumbers',markNegativeNumbersDirective)
  .directive('acceptonlypositivenumbers',acceptOnlyPositiveNumbersDirective)
  .directive('tooltip', tooltip)
  .directive('acceptonlynumbers',acceptOnlyNumbersDirective);
  app.config.globalProperties.$http = axios
  app.config.globalProperties.$messaging = firebaseMessaging
router.isReady().then(() => {
  app.mount('#app');
});