import { loadingController } from '@ionic/vue';

/**
 * Creates loading popup with backdrop
 * @param message Text to show in loading component
 * @returns {HTMLIonLoadingElement}
 */
export async function createLoadingWithOptions(message) {
    const loading = await loadingController
      .create({
        spinner: 'circular',
        duration: 0,
        message: message,
        translucent: true,
        cssClass: 'custom-class custom-loading',
        backdropDismiss: true
      });
    return loading
  }
