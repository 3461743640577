
import { version } from '/package.json'
const releaseConfig = {
    appPrefix: "rbms-fuse-mobile",
    name: "FUSE Service",
    pageTitlePrefix: "[ FUSE Service ]",
    baseURL: process.env.VUE_APP_API_ENDPOINT,//"https://rna-dev.rbms.hu/api/v4/",
    version: `${version}${process.env.VUE_APP_VERSION_SUFFIX?'-' + process.env.VUE_APP_VERSION_SUFFIX:''}`,
}

const devConfig = {
    appPrefix: "rbms-fuse-mobile",
    name: "FUSE Service",
    pageTitlePrefix: "[ FUSE Service ]",
    baseURL: process.env.VUE_APP_API_ENDPOINT,//"https://rna-dev.rbms.hu/api/v4/",
    version: `${version}${process.env.VUE_APP_VERSION_SUFFIX?'-' + process.env.VUE_APP_VERSION_SUFFIX:''}@dev`,
}

export const appConfig = process.env.NODE_ENV === 'production' ? releaseConfig : devConfig
