import axios from "axios";
import { configWithAuth } from "../api_configs";

export default {
    namespaced: true,
    state: {
        status: false,
        token: localStorage.getItem('token') || '',
        user: JSON.parse(localStorage.getItem('service_app_user') || '{}'),
        abilities: null,
     },
     actions:{
      async getUserWarehouse(store){
        const user = store.rootGetters['user/getUser']
        const supplementaryData = await store.dispatch("app/getSupplementaryData", null,{root: true})
        const userFromSupplementaryData = supplementaryData.userList.find(suppUser=>suppUser.uuid === user.uuid)
        const userVehicle = userFromSupplementaryData? userFromSupplementaryData.vehicle : null
        if(!userVehicle || !userVehicle.warehouse_id){return null}
        return supplementaryData.vehicleList.find(vehicle=> vehicle.warehouse_id === userVehicle.warehouse_id).warehouse
      },
     },
    mutations: {
        /**
         * Put user to store and to LocalStorage
         * @param state Auto passed store things
         * @param user User to store
         */
        storeUser(state,user){
            //state.user = user.abilities
            //delete user.abilities
            state.user = user
            if(user.abilities){
              state.abilities = user.abilities
            }
            localStorage.setItem("service_app_user",JSON.stringify(user))
        },
        setAbilities(state,abilities){
            state.abilities = abilities
            state.user.abilities = abilities
            localStorage.setItem("service_app_user",JSON.stringify(state.user))
        },
        /**
         * Delete user from store and from LocalStorage.
         * Send request to Api. It deletes old token from server too.
         * @param state Auto passed store things
         */
        dropUser(state){
            axios( configWithAuth('post','auth/logout')).then((resp)=> console.log(resp))
                .catch((err)=> console.log(err))
            state.user = {}
            localStorage.removeItem("service_app_user");

        }
      },
    getters: {
        getUser(state){
            return state.user
        },
        getAbilities(state){
            return state.abilities || state.user.abilities
        }
     }
  }
