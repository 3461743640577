/**
 * Handle IndexedDB connection and manage
 * database creation, read and write transactions.
 * Only create databases for worksheet storing.
 */

export default class WorksheetDB{

    db = null //Reference to IndexedDB connection
    dbVersion = 1 //IndexedDB version. Increase if database structure has modified

    /**
     * Close database connection
     */
    closeDb(){
      this.db.close()
    }
    /**
     * Delete entire database from IndexedDb
     * @returns result
     */
    deleteDatabase(){
        return new Promise ((resolve) => {
            const deleteReq = indexedDB.deleteDatabase("worksheet-db")
            deleteReq.onsuccess = (e)=>{
                return resolve(e)
            }
            deleteReq.onerror = (/*error*/)=>{ return;
            }
            deleteReq.onupgradeneeded = (/*error*/)=>{ return;
            }
        })
    }
    /**
     * Create database structure if not exists or outdated and create connection with database
     * @returns Reference to IndexedDB connection
     */
    createDatabase(){
        if (!('indexedDB' in window)) {
            console.log('This browser doesn\'t support IndexedDB');
            return;
        }
        return new Promise ((resolve) => {
            const indexedDB = window.indexedDB
            indexedDB.databases().then(async (databases) =>{
                if(databases[0] && databases[0].version !== this.dbVersion){
                    await this.deleteDatabase()
                }
                const open = indexedDB.open("worksheet-db", this.dbVersion);
                open.onupgradeneeded = async (e) =>{
                  const db = e.target.result;

                  if (!db.objectStoreNames.contains('worksheets')) {
                    db.createObjectStore('worksheets');
                  }if (!db.objectStoreNames.contains('supplementary-data')) {
                    const store = db.createObjectStore('supplementary-data',{ keyPath: "id", autoIncrement:true });
                    store.createIndex("keyName", "keyName", { unique: false });
                  }
                  if (!db.objectStoreNames.contains('batteries')) {
                      const batteryStore = db.createObjectStore('batteries');
                      batteryStore.createIndex("worksheetId", "worksheetId", { unique: false });
                  }
                };
                open.onsuccess = ()=>{
                    return resolve(open.result)
                }
                open.onerror = async (error) => {
                    if(error.target.error.name === "VersionError"){
                        indexedDB.deleteDatabase("worksheet-db")
                        return resolve(await this.createDatabase())
                    }
                }
            })


        });
    }
    /**
     * Put data into database
     * @param {string} storeName name of the indexedDB store
     * @param {Object} item Item to store
     * @param {number|string} id Primary key for item to store
     * @param {Array<number|sting>} primaryKeys Other indexes of the item to use them as keys
     * @returns {Promise} Promise of storing process. If it complete, returns transaction result
     */
    async storeItem(storeName, item, id, primaryKeys){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction([storeName], "readwrite").objectStore(storeName);
            let putRequest
            if(id){
                putRequest = objectStore.put({item: item, ...primaryKeys },parseInt(id))
              } else {
                putRequest = objectStore.put({item: item, ...primaryKeys })
              }
            putRequest.onsuccess= ()=>{
                resolve(putRequest.result)
            }
            putRequest.onerror = (err)=> {
                resolve(err)
            }
        })
    }
    /**
     * Update data in database
     * @param {string} storeName name of the indexedDB store
     * @param {Object} item Item to store
     * @param {number|string} id Primary key for item to store
     * @param {Array<number|sting>} primaryKeys Other indexes of the item to use them as keys
     * @returns {Promise} Promise of storing process. If it complete, returns transaction result
     */
    async updateItem(storeName, item, id, primaryKeys){
      this.db = await this.createDatabase()
      return new Promise ((resolve) => {
          const objectStore = this.db.transaction([storeName], "readwrite").objectStore(storeName);
          let putRequest
          if(id){
              putRequest = objectStore.put({item: item, ...primaryKeys ,id:parseInt(id)})
            } else {
              putRequest = objectStore.put({item: item, ...primaryKeys })
            }
          putRequest.onsuccess= ()=>{
              resolve(putRequest.result)
          }
          putRequest.onerror = (err)=> {
              resolve(err)
          }
      })
  }
  /**
   * Read item from database by id
   * @param {string} storeName name of the store
   * @param {number|sting} id id of the item
   * @returns {Promise} Promise of the loading process. After it complete returns the requested item
   */
    async getItem(storeName, id){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName).objectStore(storeName)
            const getRequest = objectStore.get(parseInt(id))
            getRequest.onsuccess = function() {
                resolve(getRequest.result)
            }
        })
    }
    /**
     * Delete item from database by id
     * @param {number|sting} id Item's id
     * @param {sting} storeName Name of the store
     * @returns {Promise} Promise of the deleting process.
     */
    async deleteItem(id, storeName){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName, "readwrite").objectStore(storeName)
            const deleteRequest = objectStore.delete(parseInt(id))
            deleteRequest.onsuccess = function() {
                resolve(deleteRequest.result)
            }

        })
    }
    /**
     * Delete entire worksheet with all data belongs to it by worksheet id
     * @param {number} id
     * @returns {Promise} Promise of the deleting process.
     */
    async deleteWorksheet(id){
        await this.createDatabase()
        return new Promise (function(resolve) {
            const transaction = this.db.transaction(['worksheets', 'batteries','measurements','batteryItemDetails'], "readwrite")
            const objectStore = transaction.objectStore('worksheets')
            objectStore.delete(parseInt(id))
            const batteryStore = transaction.objectStore('batteries')
            const indexes = batteryStore.index("worksheetId").getAllKeys(parseInt(id))
            const measurementsStore = transaction.objectStore('measurements')
            const bdStore = transaction.objectStore('batteryItemDetails')
            indexes.onsuccess = ()=>{
                indexes.result.forEach(i => {
                    batteryStore.delete(i)
                    //Akku szemrevételezés törlése
                    bdStore.delete(i)
                    //mérések törlése
                    const mindexes = measurementsStore.index("batteryId").getAllKeys(parseInt(i))
                    mindexes.onsuccess = ()=>{
                        mindexes.result.forEach(mi => {
                            measurementsStore.delete(mi)
                        })
                    }
                })
                resolve('delete was succesful')
            }

        })
    }
    /**
     * Get all items from database with specified index value
     * @param {string} storeName Name of the store
     * @param {string} indexName Name of indexed key
     * @param {number|string} id Key value
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getAllByKey(storeName, indexName, id){
        await this.createDatabase()
        return new Promise ((resolve) => {
            const store = this.db.transaction(storeName).objectStore(storeName)
            const storeIndex = store.index(indexName)
            let indexes = storeIndex.getAllKeys(parseInt(id))

            indexes.onsuccess = async () =>{
                indexes = indexes.result
                const result = []
                for (const i of indexes) {
                    result.push(await new Promise (function(resolve) {
                        const getRequest = store.get(i)
                        getRequest.onsuccess = function() {
                            resolve(getRequest.result)
                        }
                    }))
                }
                resolve(result)
            }
        })
    }
    /**
     * Get all primary key values (is-s) with specified index value
     * @param {string} storeName Name of the store
     * @param {string} indexName Name of indexed key
     * @param {number|string} id Key value
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getKeysByIndex(storeName, indexName, id){
        await this.createDatabase()
        return new Promise ((resolve) => {
            const store = this.db.transaction(storeName).objectStore(storeName)
            const storeIndex = store.index(indexName)
            let indexes = storeIndex.getAllKeys(parseInt(id))

            indexes.onsuccess = async () =>{
                indexes = indexes.result
                resolve(indexes)
            }
        })
    }
    /**
     * Get all items from an Object store
     * @param {string} storeName Name of an object store
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getAll(storeName){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName).objectStore(storeName)
            const getRequest = objectStore.getAll()
            getRequest.onsuccess = function(event) {
                resolve(event.target.result)
            }
        })
    }
    /**
     * Get array of item id-s from an object store
     * @param {string} storeName Name of an object store
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getIndexes(storeName){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName).objectStore(storeName)
            const getRequest = objectStore.getAllKeys()
            getRequest.onsuccess = function(event) {
                resolve(event.target.result)
            }
        })
    }
}
