import type {DirectiveBinding} from "vue";

/**
 * Update binding method
 *
 * @param {string} first_name - First name
 * @param {string} last_name - Last name
 * @return string
 */
const updateBinding = (first_name: string, last_name: string): string => {
    return `${last_name} ${first_name}`
}


export const fullNameDirective = {

    /**
     * Created hook
     *
     * @param el - HTML element
     * @param binding - Binding
     * @return void
     */
    created(el: HTMLElement, binding: DirectiveBinding): void {
        let innerHtml = ''
        if (binding.value) {
            innerHtml = updateBinding(binding.value.first_name, binding.value.last_name)
        }
        el.innerHTML = innerHtml
    },

    /**
     * Updated hook
     *
     * @param {HTMLElement} el - HTML element
     * @param {DirectiveBinding} binding - Binding
     * @return void
     */
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        let innerHtml = ''
        if (binding.value) {
            innerHtml = updateBinding(binding.value.first_name, binding.value.last_name)
        }
        el.innerHTML = innerHtml
    }

}