export default {
    namespaced: true,
    state: { 
        worksheets:{}
     },
    actions: {
        addBattery(state, batteryItem){
            if (!state.state.worksheets[batteryItem.entity.worksheetId]){
                state.state.worksheets[batteryItem.entity.worksheetId] = []
            }
            if(!state.state.worksheets[batteryItem.entity.worksheetId].some((item)=>batteryItem.entity === item)){
                state.state.worksheets[batteryItem.entity.worksheetId].push(batteryItem.entity)
            }
            return state.state.worksheets[batteryItem.entity.worksheetId]
        },
        setBatteries(state, {batteries,worksheetId}){
            state.state.worksheets[worksheetId] = batteries
            return state.state.worksheets[worksheetId]
        },
        removeBattery(state, batteryItem){
            if (!state.state.worksheets[batteryItem.entity.worksheetId]){
                return
            }
            if(state.state.worksheets[batteryItem.entity.worksheetId].some((item)=>batteryItem.entity === item)){
                state.state.worksheets[batteryItem.entity.worksheetId].splice(state.state.worksheets[batteryItem.entity.worksheetId].indexOf(batteryItem.entity), 1);
            }
            return state.state.worksheets[batteryItem.entity.worksheetId]
        },
        getBatteriesForWorksheet(state, worksheetId){
            if (!state.state.worksheets[worksheetId]){
                state.state.worksheets[worksheetId] = []
            }
            return state.state.worksheets[worksheetId]
        }
      },
    getters: { 
        
     }
  }