
import router from "../../router";
import axios from 'axios';
import {configWithAuth, loginconfig} from '../api_configs'
import i18n from "@/i18n";
import {alertController} from '@ionic/vue'


export default {
  namespaced: true,
  state: {
    status: false,
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('service_app_user') || '{}'),
  },
  actions: {
    /**
     * Start login actions
     * @param param0 Auto passed store things
     */
    login({commit, dispatch}, data) {
      loginconfig.data.email = data.email
      loginconfig.data.password = data.pass
      return axios(loginconfig)
        .then(async (response) => {
          if (response.data.data)
            commit('app/setBackgroundSyncStatus', 'pending', {root: true})
          await commit('user/storeUser', response.data.data, {root: true})
          dispatch('offlineParts/downloadAndSaveParts', null, {root: true})
            .then(() => dispatch("app/downloadAndStoresupplementaryData", null, {root: true})
              .then(() => commit('app/setBackgroundSyncStatus', 'complete', {root: true})))

          return response.data

        })

    },
    /**
     * Start logout actions
     * @param param0 Auto passed store things
     */
    logout({dispatch, commit}) {
      dispatch("load/stopLoad", null, {root: true})
      const doLogout = () => {
        dispatch("load/startLoad", i18n.global.t("Logging out"), {root: true})
        commit('user/dropUser', null, {root: true})
        router.push('/auth/login');
      }

      window.indexedDB.databases().then(async (r) => {
        if (r.length > 0) {
          const alert = await alertController.create({
            header: i18n.global.t("Logout"),
            message: i18n.global.t("All offline data will be deleted. Are you sure?"),
            buttons: [
              {
                text: i18n.global.t("Cancel"),
                role: 'cancel',
              },
              {
                text: i18n.global.t("Yes"),
                role: 'confirm',
                handler: async () => {
                  await dispatch("load/startLoad", "Logging out", {root: true})
                  await dispatch("offlineWorksheet/deleteAllLocally", null, {root: true})
                  await dispatch("offlineWorksheet/deleteAllWorksheetDataFromMemory", null, {root: true})
                  doLogout()
                },
              },
            ],
          });

          await alert.present();
        } else {
          doLogout()
        }

      })


    },
    /**
     * Checks token validity
     * @returns {Promise<any>}
     */
    checkTokenValidity({commit}) {
      return axios(configWithAuth('get', 'check-token-validity', {},
        {fcm_token: localStorage.getItem("fcm_key") || undefined},
        {"X-App-Module": "service"}
      )).then((response) => {
        commit('user/setAbilities', response.data.data.abilities, {root: true})
        return response
      })
    },
    async changeUserByAccessToken(store, data) {
      console.log(data)
      const response = await axios(configWithAuth('get', 'check-token-validity', {}, {}, {
        "X-App-Module": "service",
        'Authorization': 'Bearer ' + data.token,
        'X-User-UUID': data.uuid,
        'X-Requires-Full-Auth-Data': true
      }))
      if (response.data.data.__uid) {
        localStorage.setItem('token', data.token)
        await store.commit('user/dropUser', null, {root: true})
        await store.commit('user/storeUser', {...response.data.data, token: data.token, uuid: data.uuid}, {root: true})
        if (data.reload) {
          location.reload()
        }
      }
      return response
    }
  },
}
