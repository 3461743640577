const STATUS = {
  OPEN : 0,  // nyitott
  ASSIGNED : 3,  // hozzárendelt usere van
  IN_PROGRESS : 7, // elkezdve
  CLOSED : 10, // lezárva
  APPROVED : 50, // jóváhagyva
  BILLABLE : 80, // számlázható
  WAITING_PO : 90, // partner rendelészzámra vár
  COMPLETE : 100, // elvégzett
  INVALIDATED : 127 // sztornó
}
export {STATUS}
